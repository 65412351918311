<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					예약
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">상품정보</h2>
						<p class="booking-top__price">
							<span>3건</span>
							<span>
							<strong class="red">190,000</strong>원
						</span>
						</p>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약 숙소 1 -->
							<div class="booking-item">
								<div class="booking-item__badge">숙소</div>
								<h3 class="booking-item__title">종로 레몬트리</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">숙박</span>
									102(개별 수영장 + 복층)
								</p>
								<div class="booking-item__date">
									2021.12.13(월) 11:00 ~ 16:00
								</div>
								<div class="booking-item__price">
									<strong>30,000</strong>원
								</div>
								<div class="booking-item__select">
									<!-- [D] 기본 쿠폰 선택 버튼 -->
									<button type="button" class="btn-select">
										<span class="btn-select__wrap">
											<span class="btn-select__title">
												로그인 후 할인 혜택 받으세요!
											</span>
										</span>
										<i class="icon icon-right--light"></i>
									</button>
								</div>
							</div>
						</div>
					</article>
					<!-- 예약자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title"> 예약자 정보 </h3>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									이름 <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap">
									<input type="text" class="ipt-frm" :placeholder="t('10835')" >
								</div>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									휴대폰 번호 <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap">
									<input type="tel" class="ipt-frm" :placeholder="t('10827')" >
									<div class="frms__btns">
										<button type="button" class="btn-link">
											인증하기
										</button>
									</div>
								</div>
							</div>
						</div>
					</article>
					<!-- 이용자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">방문자 정보</h3>
							<p class="description">
								실제 방문하시는 분 정보 입력
							</p>
							<div class="checkbox" style="margin:10px 0 25px">
								<label>
									<input type="checkbox" class="chk blind">
									<span class="checkbox__bg"></span>
									예약자 정보와 동일합니다.
								</label>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									이름 <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap">
									<input type="text" class="ipt-frm" :placeholder="t('10835')" >
								</div>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									휴대폰 번호 <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap">
									<input type="tel" class="ipt-frm" :placeholder="t('10778')">
								</div>
							</div>
						</div>
					</article>
					<!-- 숙소 방문 수단 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="flex">
								<h3 class="booking-box__title">숙소 방문 수단 <span class="dot dot--red"></span></h3>
								<button type="button" class="btn-link">선택하기</button>
							</div>
							<ul class="booking-visit">
								<li>
									숙소 방문 수단을 선택해 주세요.
								</li>
								<li>
									종로레몬트리
									<span>도보</span>
								</li>
								<li>
									종로레몬트리
									<span>차량</span>
								</li>
								<li>
									경주빌라
									<span class="red">주차가 불가능한 숙소입니다.</span>
								</li>
							</ul>
						</div>
					</article>
					<!-- 금액 및 할인 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">금액 및 할인 정보</h3>
							<!-- 예약 금액 -->
							<ul class="info">
								<li>
									<h4 class="info__title">
										예약금액
									</h4>
									<div class="info__wrap">
										<div class="info__text">
											<strong>199,000</strong>원
										</div>
									</div>
								</li>
							</ul>
							<div class="guest-button">
								<button type="button" class="btn btn-change">
									로그인하고 할인 혜택 받으세요!
								</button>
							</div>
							<!-- 총 결제 금액 -->
							<ul class="info">
								<li class="point" style="border-top:none">
									<h4 class="info__title">
										총 결제금액
									</h4>
									<div class="info__wrap">
										<div class="info__text">
											<strong class="red">190,000</strong>원
										</div>
									</div>
								</li>
							</ul>
						</div>
					</article>
					<!-- 결제 수단 선택 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">결제수단 선택</h3>
							<!-- 결제 수단 목록 -->
							<div class="checkout">
								<div class="btns">
									<!-- [D] 혜택 있을 경우 btn-checkout--benefit 클래스 추가 -->
									<!-- [D] 체크 되었을 경우 active 클래스 추가 -->
									<button type="button" class="btn-checkout btn-checkout--benefit">
										<img src="@/assets/images/icon_payco.png" alt="payco">
									</button>
									<button type="button" class="btn-checkout">
										신용/체크카드
									</button>
									<button type="button" class="btn-checkout btn-checkout--benefit">
										<img src="@/assets/images/icon_naverpay.png" alt="naver pay"
										     style="height:20px">
									</button>
									<button type="button" class="btn-checkout">
										<img src="@/assets/images/icon_kakaopay.png" alt="kakao pay"
										     style="height:18px">
									</button>
									<button type="button" class="btn-checkout">
										<img src="@/assets/images/icon_ssg.png" alt="ssgpay">
									</button>
									<button type="button" class="btn-checkout active">
										<img src="@/assets/images/icon_lpay.png" alt="lpay">
									</button>
									<button type="button" class="btn-checkout btn-checkout--benefit">
										간편 계좌이체
									</button>
									<button type="button"></button>
								</div>
							</div>
							<!-- 혜택 안내 -->
							<div class="benefit">
								<div class="benefit__title">
									<span class="benefit__badge">혜택</span>
									<span class="benefit__logo">
										<img src="@/assets/images/icon_payco.png" alt="payco">
										신용카드
									</span>
								</div>
								<div class="benefit__wrap">
									<div class="benefit__name">
										페이코 결제 혜택
									</div>
									<div class="benefit__info">
										<span class="red">5만원</span> 이상 결제시 <span class="red">5천원</span> 즉시 <span
										class="red">할인!</span>
									</div>
									<div class="benefit__date">
										2021.12.12 ~ 2021.12.30까지
									</div>
								</div>
							</div>
							<!-- 현금영수증 -->
							<div class="receipt">
								<div class="receipt__items flex">
									<div class="receipt__item">
										<label>
											<input type="radio" class="rad blind" name="isDormant">
											<span class="receipt__radio"></span>
											소득공제
										</label>
									</div>
									<div class="receipt__item">
										<label>
											<input type="radio" class="rad blind" name="isDormant">
											<span class="receipt__radio"></span>
											지출증빙
										</label>
									</div>
									<div class="receipt__item">
										<label>
											<input type="radio" class="rad blind" name="isDormant">
											<span class="receipt__radio"></span>
											미발행
										</label>
									</div>

								</div>
								<div class="receipt__wrap">
									<div class="slt">
										<input type="tel" class="ipt-frm ipt-slt" value="휴대폰 번호" readonly>
										<div class="frms__btns">
											<button type="button" class="btn-frmreset"><i class="icon icon-down"></i>
											</button>
										</div><!-- [D] 선택시 input에 입력되고, icon은 제거 -->
										<ul class="slt__list">
											<li>휴대폰 번호</li>
											<li>현금영수증 카드 번호</li>
										</ul>
										<input type="tel" class="ipt-frm ipt-slt" placeholder="휴대폰 번호를 입력하세요" value="010-1234-1234">
									</div>
									<div class="receipt__notice">
										포인트 또는 쿠폰 이용 시, 실제 현금성으로 결제 된
										금액만 현금영수증이 발행됩니다.
									</div>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span> 현금영수증 발급을 위하여 휴대폰번호 또는
											현금영수증 카드 번호를 수집하며 5년간 처리에
											동의합니다.
										</label>
									</div>
								</div>
							</div>
							<!-- 다음 결제 수단으로 사용 여부 -->
							<div class="next">
								<div class="checkbox">
								<label>
									<input type="checkbox" class="chk blind">
									<span class="checkbox__bg"></span> 이 결제 수단을 다음에도 사용
								</label>
							</div>
							</div>
						</div>
					</article>
					<!-- 안내와 약관동의 -->
					<article class="booking-box booking-box--last">
						<div class="box box--reverse">
							<!-- 공지 -->
							<div class="notice__wrap">
								<div class="notice__item">
									<h4 class="notice__title">
										<i class="icon icon-warning--point"></i>
										취소 불가 및 수수료
									</h4>
									<p class="description">
										취소 및 환불규정에 따라 취소불가, 수수료가
										발생할 수 있습니다.
									</p>
								</div>
								<div class="notice__item">
									<h4 class="notice__title">
										<i class="icon icon-warning--point"></i>
										미성년자 및 법정대리인 필수
									</h4>
									<p class="description">
										미성년자는 법정대리인 동행 없이 투숙이
										불가능합니다.
									</p>
								</div>
							</div>
							<!-- 동의 -->
							<ul class="agrees">
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span>
											<span class="agree__title">이용약관에 전체 동의합니다</span>
										</label>
									</div>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span>
											<span class="agree__title">숙박 상품 이용 규칙 동의 (필수)</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span>
											<span class="agree__title">취소/환불 규정 동의 (필수)</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span>
											<span class="agree__title">개인정보 제 3자 제공 동의 (필수)</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind">
											<span class="checkbox__bg"></span>
											<span class="agree__title">개인정보 수집 및 이용동의 (필수)</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light">보기</i>
									</button>
								</li>
							</ul>
							<p class="description">
								운영사는 통신판매중개자로서, 통신판매의 당사자가
								아니라는 사실을 고지하며, 상품의 예약, 이용 및 환불 등과
								관련한 의무와 책임은 각 판매자에게 있습니다.
							</p>
							<div class="booking-box__button">
								<div class="btns">
									<button type="button" class="btn btn-confirm">
										156,000원 결제하기
									</button>
								</div>
							</div>
						</div>
					</article>
				</section>
			</div>
		</div>
	</div>
</template>